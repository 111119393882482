<template>
    <auth-layout>
        <div class="ce-login-card">
            <h3 class="ce-login-card-title">Send Verification Code</h3>

            <div class="ce-login-card-item-wrap">
                <p>Your computer (device) you are logging in from is not currently recognized for your account,
                    to verify
                    your login we need to send a Verification code to one of the following options.</p>
                <div class="input-group">
                    <div class="radio-btn" v-if="user.email">
                        <input type="radio" name="code_option" value="email" v-model="code_option"
                               checked="checked">
                        <span style="font-weight: bold;">Email ({{ user.email }}) </span>
                    </div>
                    <div class="radio-btn" v-if="user.phone">
                        <input type="radio" name="code_option" value="phone" v-model="code_option">
                        <span style="font-weight: bold;">Phone ({{ user.phone }}) </span>
                    </div>

                </div>

            </div>

            <div class="ce-login-btn-wrap">
                <a href="/" role="button" class="btn ce-btn-cancel-submit">Cancel</a>
                <button v-on:click="authenticate(code_option)" class="btn ce-btn-login-submit">
                    Submit
                </button>
            </div>
        </div>
    </auth-layout>
</template>
<script>
    import {mapState, mapActions} from 'vuex';
    import {ACTION_AUTHENTICATE_TYPE} from '@/store/modules/User';
    import AuthLayout from '../layouts/AuthLayout';

    export default {
        data() {
            return {
                code_option: 'email',
            }
        },
        components: {
            'auth-layout': AuthLayout
        },
        computed: mapState('User',['user']),
        methods: {
            ...mapActions('User',{
                authenticate: ACTION_AUTHENTICATE_TYPE
            })
        }
    }
</script>
